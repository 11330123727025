import React from "react";
import styles from "./LogoAndName.module.css";

const LogoAndName = (props) => {
  return (
    <a className={styles.logoAndCompanyNameDiv} href="/">
      <div>
        <img className={styles.logo} src={props.logo} alt="logo" />
      </div>
      <div className={styles.companyNameDiv}>
        <h1 className={`${styles.companyName} headerTextColor`}>
          {props.companyName?.toUpperCase()}
        </h1>
      </div>
    </a>
  );
};

export default React.memo(LogoAndName);
