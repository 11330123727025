import { useEffect, useRef, useState } from "react";
import Backdrop from "../../UI/Backdrop/Backdrop";
import Card from "../../UI/Card/Card";
import LoadingSpinner from "../../UI/LoadingSpinner/LoadingSpinner";
import styles from "./IFrame.module.css";

const IFrame = (props) => {
  const [showSpinner, setShowSpinner] = useState(true);
  const iframeRef = useRef(null);

  const right = props.width
    ? (100 - Number(props.width.replace("%", ""))) / 2
    : "";

  useEffect(() => {
    // framed.htm:
    //   window.onmessage = function(event) {
    //     event.source.postMessage(document.body.innerHTML, event.origin);
    // };
    // Main page:
    // window.onmessage = function (event) {
    //   alert(event.data);
    // };
    // Trigger:
    // iframeRef.current.contentWindow.postMessage("", "*")
    // console.log(iframeRef.current.contentWindow.postMessage("", "*"));
  }, [showSpinner]);

  return (
    <>
      <Backdrop onClose={props.onClose} />
      {showSpinner && <LoadingSpinner />}
      <Card
        className={styles.iframeParent}
        style={{ width: props.width, right: `${right}%` }}
      >
        <div className={styles.header}>
          <h3>{props.heading}</h3>
          <div className={styles.iframeCloseX} onClick={props.onClose}>
            X
          </div>
        </div>
        <div className={styles.iframeDiv}>
          <iframe
            ref={iframeRef}
            onLoad={(e) => {
              setShowSpinner(false);
            }}
            title={props.title}
            src={props.src}
            name={props.name}
            className={styles.iframe}
            style={{
              height: props.height ? props.height : "70vh",
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default IFrame;
