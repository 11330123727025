import React from "react";
import styles from "./Testimonial.module.css";
import parse from "html-react-parser";

const TestimonialSingle = (props) => {
  return (
    <div>
      <div className={`${styles["section-three-main-div"]} testimonial`}>
        <div className={styles["section-three-sub-div-one"]}>
          <div
            className={`${styles["quotes-img"]} ${styles["quotes-img-right"]}`}
          />
          <div className={`feedbackText--0 ${styles["main-quotes-div"]} `}>
            {parse(`${props.testimonial}`)}
          </div>
          <div
            className={`${styles["quotes-img"]} ${styles["quotes-img-left"]}`}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TestimonialSingle);
