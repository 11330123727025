import React from "react";
import styles from "./NameAndAddress.module.css";

const NameAndAddress = (props) => {
  return (
    <div className={styles.nameAndAddress}>
      <div className={styles["footer-logo"]}>
        <img
          className={styles["footer-logo-img"]}
          alt="logo"
          src={props.logo}
        />
      </div>
      <div className={styles["company-info"]}>
        <div className={styles.companyName}>{props.businessName}</div>
        <p className={styles.address}>{props.address}</p>
      </div>
    </div>
  );
};

export default React.memo(NameAndAddress);
