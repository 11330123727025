import React from "react";
import PoweredBy from "./PoweredBy/PoweredBy";
import styles from "./Footer.module.css";
import NameAndAddress from "./NameAndAddress/NameAndAddress";
import SocialLinks from "./SocialLinks/SocialLinks";

const Footer = (props) => {
  return (
    <>
      <footer className={`${styles.footer}  footerAndSearch`}>
        <div className={styles.footerMainDiv}>
          <NameAndAddress
            businessName={props.portal.business_name}
            address={props.portal.address}
            logo={props.portal.logo_aws}
          />
          <SocialLinks
            phone={props.portal.phone}
            email={props.portal.email}
            facebook={props.portal.facebook}
            instagram={props.portal.instagram}
            linkedIn={props.portal.linked_in}
            twitter={props.portal.twitter}
            privacyPolicy={props.portal.privacy_policy}
            servicePolicy={props.portal.service_policy}
            sitemap={props.portal.sitemap_link}
          />
          <PoweredBy
            showMenu={props.portal.show_menu}
            menus={props.portal.menus}
          />
        </div>
      </footer>
    </>
  );
};

export default React.memo(Footer);
