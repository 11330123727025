import { useCallback, useState } from "react";
import singleWebPortalApi from "./api/JavaApi/SingleWebPortalApi";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import HomePage from "./components/Pages/HomePage";
import Theme from "./Theme";
import AllListings from "./api/TenantcubeApi/AllListings";
import ListingDetailsPage from "./components/Pages/ListingDetailsPage";

function App() {
  const portal = singleWebPortalApi(); // setIsLoading
  const sliderImagesData = AllListings(portal.api_key); // setIsLoading

  const [showSingleListing, setShowSingleListing] = useState(false);
  const [singleListingData, setSingleListingData] = useState({});

  const sliderImgClick = useCallback((singleSettingData) => {
    setSingleListingData(singleSettingData);
  }, []);

  const openSingleListing = useCallback(() => {
    setShowSingleListing(true);
  }, []);

  const closeSingleListing = useCallback(() => {
    setShowSingleListing(false);
  }, []);

  return (
    <div className={`parent App`}>
      <Theme themeColor={portal.theme_color} />
      <Header
        logo={portal.logo_aws}
        companyName={portal.business_name}
        email={portal.email}
        phone={portal.phone}
        orgUrlName={
          sliderImagesData[0]?.orgUrlName ? sliderImagesData[0]?.orgUrlName : ""
        }
      />
      {!showSingleListing && (
        <HomePage
          // openMaintenanceRequestPage={openMaintenanceRequestPage}
          sliderImgClick={sliderImgClick}
          openSingleListing={openSingleListing}
          sliderImagesData={sliderImagesData}
          portal={portal}
        />
      )}
      {showSingleListing && (
        <ListingDetailsPage
          singleListingData={singleListingData}
          closeSingleListing={closeSingleListing}
        />
      )}

      <Footer portal={portal} />
    </div>
  );
}

export default App;
