import styles from "./PoweredBy.module.css";
import tenantcubeLogo from "../../../assets/footer/tenantcube_white.webp";
import MenuLinksFooter from "./MenusLinkFooter";
import React, { useMemo } from "react";

const PoweredBy = (props) => {
  const { showMenu, menus } = useMemo(() => props, [props]);

  return (
    <div className={styles.footerLogoDiv}>
      {showMenu ? <MenuLinksFooter menus={menus} /> : ""}
      <div className={styles.DevelopedByLabel}>Powered By</div>
      <a href="https://www.tenantcube.com/">
        <img
          className={styles.rentSyncLogoImg}
          src={tenantcubeLogo}
          alt="tenantcube"
        />
      </a>
    </div>
  );
};

export default React.memo(PoweredBy);
