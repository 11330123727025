import React, { useCallback, useMemo } from "react";
import styles from "./PlanRoom.module.css";

const PlanRoom = (props) => {
  const returnValidLink = useCallback((link) => {
    if (link === undefined) return "";

    const noWhiteSpace = link.trim();

    if (noWhiteSpace.slice(0, 8) === "https://") {
      return link;
    }

    return "https://" + noWhiteSpace;
  }, []);

  const planRoom = useMemo(() => {
    if (props.planRoom) return JSON.parse(props.planRoom);
  }, [props.planRoom]);

  return (
    <div className={styles["section-four-div"]}>
      <img
        className={styles.planRoomBgImg}
        src={props.backgroundImg}
        alt="bgImg"
      />

      <div className={styles["set-width"]}>
        <div className={styles["content-container"]}>
          <div className={styles["dummy-content"]} />
          <div className={styles["content-body"]}>
            <h2 className={`${styles["plan-room-heading"]} textColor`}>
              {planRoom.heading}
            </h2>
            <p className={styles["plan-room-text"]}>{planRoom.subHeading}</p>
            <a
              className={`${styles["visit-plan-room"]} button`}
              // button link
              href={returnValidLink(planRoom.buttonText)}
            >
              Visit the plan room
              {/* {planRoom.buttonText} */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PlanRoom);
