import { useEffect, useState, useCallback, useMemo } from "react";
import {
  JAVA_API_DEV,
  JAVA_API_LOCAL,
  JAVA_API_PROD,
} from "../../helpers/constants";

const SingleWebPortalApi = () => {
  const [portalSetting, setPortalSetting] = useState("");
  const portalCall = useCallback(async () => {
    const checkIfValidUUID = (str) => {
      // Regular expression to check if string is a valid UUID
      const regexExp =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      return regexExp.test(str);
    };

    const DOMAIN = window.location.hostname;
    const DOMAIN_PARTS = DOMAIN.split(".");
    const DEV_OR_LIVE = DOMAIN_PARTS[1];

    const tenantcubelistings = DOMAIN_PARTS[2]; //tenantcubelistings

    let JAVA_API_URL = JAVA_API_PROD;

    let rowType = "L";

    if (
      DEV_OR_LIVE === "dev-preview" &&
      tenantcubelistings === "tenantcubelistings"
    ) {
      console.log(".dev-preview");
      JAVA_API_URL = JAVA_API_DEV;
      rowType = "P";
    } else if (DEV_OR_LIVE === "localhost") {
      console.log(".localhost");
      JAVA_API_URL = JAVA_API_LOCAL;
      rowType = "P";
    } else if (
      DEV_OR_LIVE === "live" &&
      tenantcubelistings === "tenantcubelistings"
    ) {
      console.log(".live");
      JAVA_API_URL = JAVA_API_PROD;
      rowType = "L";
    } else if (
      DEV_OR_LIVE === "preview" &&
      tenantcubelistings === "tenantcubelistings"
    ) {
      console.log(".preview");
      JAVA_API_URL = JAVA_API_PROD;
      rowType = "P";
    }

    try {
      if (checkIfValidUUID(DOMAIN_PARTS[0])) {
        console.log("fetch using uuid");
        const uuid = DOMAIN_PARTS[0];
        const response = await fetch(JAVA_API_URL + "/portal/check/" + uuid);
        const domainId = await response.json();
        if (domainId === 0) return;
        const response2 = await fetch(
          `${JAVA_API_URL}/${"portal/single"}/${domainId}?type=${rowType}`
        );
        const data = await response2.json();
        setPortalSetting(data.portal);
        return;
      } else {
        console.log("fetch using domain");
        // NOT UUID SO, IT IS DOMAIN

        let liveDomain = DOMAIN;
        const liveDomainParts = DOMAIN.split(".");
        if (liveDomainParts[0] === "www") {
          liveDomain = `${liveDomainParts[1]}.${liveDomainParts[2]}`;
        }

        const response = await fetch(
          JAVA_API_URL + "/portal/check/" + liveDomain
        );
        const domainId = await response.json();
        if (domainId === 0) return;
        const response2 = await fetch(
          `${JAVA_API_URL}/${"portal/single"}/${domainId}?type=${rowType}`
        );
        const data = await response2.json();
        setPortalSetting(data.portal);
        return;
      }
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    portalCall();
  }, [portalCall]);

  return useMemo(() => portalSetting, [portalSetting]);
};

export default SingleWebPortalApi;
