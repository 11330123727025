import { useEffect, useMemo, useState } from "react";
import {
  ALL_LISTINGS_TWO,
  TENANTCUBE_LIVE,
  TENANTCUBE_STAGING,
} from "../../helpers/constants";
import imgSliderDefault from "../../assets/imgSliderDefault.webp";

let HOST = TENANTCUBE_LIVE;
const AllListingsDetails = (apiKey) => {
  const [allListings, setAllListings] = useState([]);
  const [units, setUnits] = useState([]);
  const [properties, setProperties] = useState([]);
  const [listingDetails, setListingDetails] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [sliderImagesData, setSliderImagesData] = useState([]);

  useEffect(() => {
    if (apiKey === undefined) return;

    const fullDomain = window.location.hostname;
    const domainParts = fullDomain.split(".");
    const subDomain = domainParts[1];

    if (subDomain === "dev-preview" || subDomain === "localhost") {
      HOST = TENANTCUBE_STAGING;
    }

    fetch(HOST + ALL_LISTINGS_TWO, {
      headers: {
        "api-key": `${apiKey}`,
      },
    })
      .then((el) => el.json())
      .then((data) => {
        setAllListings(() => data.data);
        setUnits(() => {
          if (data.included) {
            const units = data.included.filter(
              (element) => element.type === "units"
            );
            return units;
          }
          return [];
        });
        setProperties(() => {
          if (data.included) {
            const properties = data.included.filter(
              (element) => element.type === "properties"
            );
            return properties;
          }
          return [];
        });

        setListingDetails(() => {
          if (data.included) {
            const listingDetails = data.included.filter(
              (element) => element.type === "listing-details"
            );
            return listingDetails;
          }
          return [];
        });

        setOrganisation(() => {
          if (data.included) {
            const [organisation] = data.included.filter(
              (element) => element.type === "organisations"
            );
            return organisation;
          }
          return [];
        });
      });
  }, [apiKey]);

  useEffect(() => {
    if (properties.length === 0) return;
    if (allListings.length === 0) return;
    if (units.length === 0) return;

    let imagesArray = [];

    allListings.forEach((element, i) => {
      const allPhotos = [];
      console.log("local test");

      units[i]?.attributes.photos?.forEach((el) => {
        if (el) allPhotos.push(HOST + el.url);
      });
      properties[i]?.attributes.photos?.forEach((el) => {
        if (el) allPhotos.push(HOST + el.url);
      });

      imagesArray.push({
        imageUrl: `${
          properties[i]?.attributes["cover-image"]
            ? HOST + properties[i]?.attributes["cover-image"].url
            : units[i]?.attributes["cover-image"]
            ? HOST + units[i]?.attributes["cover-image"].url
            : imgSliderDefault
        }`,
        addressOne: element.attributes.street,
        addressTwo: `${element.attributes.province} ${element.attributes.zipcode}, ${element.attributes["country-code"]}`,
        price: element.attributes.rent,
        shareUrl: element.attributes["share-url"],
        photos: allPhotos, // properties[i].attributes.photos?.map((el) => HOST + el.url);
        name: element.attributes.name,
        beds: element.attributes.beds,
        bath: element.attributes.bath,
        size: element.attributes.size,
        parking: element.attributes.parking,
        description: element.attributes.description,
        propDescription: properties[i]?.attributes.description,
        amenities: listingDetails[i]?.attributes.amenities,
        utilities: listingDetails[i]?.attributes.utilities,
        features: listingDetails[i]?.attributes.features,
        builtOn: listingDetails[i]?.attributes["built-on"],
        contactName: listingDetails[i]?.attributes["contact-name"],
        email: listingDetails[i]?.attributes.email,
        laundry: listingDetails[i]?.attributes.laundry,
        petFriendly: listingDetails[i]?.attributes["pet-friendly"],
        phone: listingDetails[i]?.attributes.phone,
        rentersInsure: listingDetails[i]?.attributes["renters-insure"],
        showUtility: listingDetails[i]?.attributes["show-utility"],
        unitType: listingDetails[i]?.attributes["unit-type"],
        number: units[i]?.attributes.number,
        street: element.attributes.street,
        city: element.attributes.city,
        province: element.attributes.province,
        zipcode: element.attributes.zipcode,
        countryCode: element.attributes["country-code"],
        orgName: organisation?.attributes?.name,
        orgEmail: organisation?.attributes?.email,
        orgPhone: organisation?.attributes?.phone,
        orgDesc: organisation?.attributes?.description,
        orgUrlName: organisation?.attributes?.["url-name"],
        orgWebsite: organisation?.attributes?.website,
      });
    });

    if (imagesArray.length === 1) {
      imagesArray.push(imagesArray[0]);
      imagesArray.push(imagesArray[0]);
    }
    if (imagesArray.length === 2) {
      imagesArray.push(imagesArray[0]);
      imagesArray.push(imagesArray[1]);
    }
    setSliderImagesData(imagesArray);
  }, [organisation, units, listingDetails, properties, allListings]);

  return useMemo(() => sliderImagesData, [sliderImagesData]);
};

export default AllListingsDetails;
