import React, { useMemo } from "react";

const Theme = ({ themeColor }) => {
  const colorSets = useMemo(() => {
    return {
      default: {
        testimonial: "#CFCFCF",
        button: "#6d6d6d",
        footer: "#212121",
        featuresHover: "#f5f5f5",
        textColor: "#1c1c1c",
        searchBtn: "#6d6d6d",
      },
      violet: {
        testimonial: "#c0b3c2",
        button: "#6a1b9a",
        footer: "#220842",
        featuresHover: "#f3e5f5",
        textColor: "#0e011e",
        searchBtn: "#6a1b9a",
      },
      grey: {
        testimonial: "#CFCFCF",
        button: "#6d6d6d",
        footer: "#212121",
        featuresHover: "#f5f5f5",
        textColor: "#1c1c1c",
        searchBtn: "#6d6d6d",
      },
      orange: {
        testimonial: "#D6C9B6",
        button: "#b53d00",
        footer: "#5e1d00",
        featuresHover: "#fff3e0",
        textColor: "#260c00",
        searchBtn: "#b53d00",
      },
      red: {
        testimonial: "#E6D1D4",
        button: "#c62828",
        footer: "#380000",
        featuresHover: "#ffebee",
        textColor: "#190000",
        searchBtn: "#c62828",
      },
      blue: {
        testimonial: "#BECBD4",
        button: "#1976d2",
        footer: "#042966",
        featuresHover: "#e3f2fd",
        textColor: "#001c47",
        searchBtn: "#1976d2",
      },
      green: {
        testimonial: "#CCDED1",
        button: "#388e3c",
        footer: "#0b300e",
        featuresHover: "#f1fff4",
        textColor: "#003300",
        searchBtn: "#388e3c",
      },
    };
  }, []);

  const { testimonial, button, footer, featuresHover, textColor, searchBtn } =
    useMemo(
      () =>
        colorSets[themeColor] ? colorSets[themeColor] : colorSets["default"],
      [colorSets, themeColor]
    );

  return (
    <style>
      {`
    .testimonial {
      background-color: ${testimonial};
    }
    .button {
      background-color: ${button};
    }

    .footerAndSearch {
      background-color: ${footer};
    }
    .features:hover {
      background-color: ${featuresHover};
    }

    .textColor {
      color: ${textColor};
    }

    .searchBtnAndSlider {
      background-color: ${searchBtn};
    }

    .textColorTwo {
      color: ${searchBtn};
    }

    .headerTextColor {
      color: white;
    }

    `}
    </style>
  );
};

export default React.memo(Theme);
