// JAVA API CONSTANT
export const GET_SINGLE_SETTING =
  "https://dev-api.tenantcubelistings.com/portal/single"; // dev
// export const GET_SINGLE_SETTING =
//   "https://website-prod-elb-1707093701.ca-central-1.elb.amazonaws.com/portal/single"; // prod

export const JAVA_API_LOCAL = "http://localhost:8080";
export const JAVA_API_DEV = "https://dev-api.tenantcubelistings.com";
export const JAVA_API_PROD = "https://api.tenantcubelistings.com";

//  TENANTCUBE URL CONSTANTS
// export const HOST = "https://staging.tenantcube.com";
export const TENANTCUBE_STAGING = "https://staging.tenantcube.com";
export const TENANTCUBE_LIVE = "https://my.tenantcube.com";
export const ALL_LISTINGS_TWO =
  "/api/portal/listings?include=unit,unit.property,listing-detail,organisation";

// OTHER CONSTANTS
export const TESTIMONIAL_DELAY = 7000; // 7 SECONDS
