import styles from "./SocialLinks.module.css";
import phoneIcon from "../../../assets/footer/phone.webp";
import emailIcon from "../../../assets/footer/email.webp";
import facebookIcon from "../../../assets/footer/facebook.webp";
import instagramIcon from "../../../assets/footer/instagram.webp";
import linkedinIcon from "../../../assets/footer/linkedin.webp";
import twitterIcon from "../../../assets/footer/twitter.webp";
import React, { useCallback, useMemo } from "react";

const SocialLinks = (props) => {
  const {
    facebook,
    instagram,
    linkedIn,
    twitter,
    servicePolicy,
    privacyPolicy,
    sitemap,
  } = useMemo(() => props, [props]);

  const returnValidLink = useCallback((link) => {
    if (link === undefined) return "";

    const noWhiteSpace = link.trim();

    if (noWhiteSpace.slice(0, 8) === "https://") {
      return link;
    }

    return "https://" + noWhiteSpace;
  }, []);

  return (
    <div className={styles.socialLinks}>
      {facebook || instagram || linkedIn || twitter ? (
        <div className={styles.socialHeader}>Follow us on social media</div>
      ) : (
        ""
      )}

      <div className={styles.socialIcons}>
        {props.phone && (
          <a href={`tel:${props.phone}`}>
            <img className={styles.socialLogo} src={phoneIcon} alt={"phone"} />
          </a>
        )}

        {props.email && (
          <a href={`mailto:${props.email}`}>
            <img className={styles.socialLogo} src={emailIcon} alt={"email"} />
          </a>
        )}
        {props.facebook && (
          <a href={returnValidLink(facebook)} rel="noreferrer" target="_blank">
            <img
              className={styles.socialLogo}
              src={facebookIcon}
              alt={"facebook"}
            />
          </a>
        )}
        {props.instagram && (
          <a href={returnValidLink(instagram)} rel="noreferrer" target="_blank">
            <img
              className={styles.socialLogo}
              src={instagramIcon}
              alt={"instagram"}
            />
          </a>
        )}
        {props.linkedIn && (
          <a href={returnValidLink(linkedIn)} rel="noreferrer" target="_blank">
            <img
              className={styles.socialLogo}
              src={linkedinIcon}
              alt={"linked-in"}
            />
          </a>
        )}
        {props.twitter && (
          <a href={returnValidLink(twitter)} rel="noreferrer" target="_blank">
            <img
              className={styles.socialLogo}
              src={twitterIcon}
              alt={"twitter"}
            />
          </a>
        )}
      </div>

      {props.servicePolicy && (
        <div
          className={styles.customerServicePolicy}
          rel="noreferrer"
          target="_blank"
        >
          <p
            className={styles.footerLink}
            // href={returnValidLink(servicePolicy)}
          >
            Accessibility Customer Service Policy
          </p>
        </div>
      )}

      <div className={styles.privacyPolicyAndSitemap}>
        {props.privacyPolicy && (
          <a
            className={styles.footerLink}
            href={returnValidLink(privacyPolicy)}
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        )}
        {props.sitemap && (
          <a
            className={styles.footerLink}
            href={returnValidLink(sitemap)}
            rel="noreferrer"
            target="_blank"
          >
            Sitemap
          </a>
        )}
      </div>
    </div>
  );
};

export default React.memo(SocialLinks);
