import { useEffect, useState } from "react";
import styles from "../PoweredBy/PoweredBy.module.css";

const MenuLinksFooter = (props) => {
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    if (props.menus) {
      setMenus(JSON.parse(props.menus));
    }
  }, [props.menus]);

  return (
    <ul className={styles.footerUl}>
      {menus.map((menu, i) => {
        return (
          <li key={i}>
            <a
              href={menu.link}
              rel="noreferrer"
              target="_blank"
              className={`${styles.footerLink} headerTextColor`}
              key={i}
            >
              <div>{menu.title}</div>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuLinksFooter;
