import React, { useCallback, useEffect, useMemo, useState } from "react";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import styles from "./ImageSlider.module.css";
import LoadingSpinner from "../UI/LoadingSpinner/LoadingSpinner";
import Backdrop from "../UI/Backdrop/Backdrop";

const ImageSlider = (props) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [imgDragState, setImgDragState] = useState({
    clientXonMouseDown: null,
    clientYonMouseDown: null,
  });

  const listingData = useMemo(() => {
    if (props.sliderImagesData) return props.sliderImagesData;
    else return [];
  }, [props.sliderImagesData]);

  const imgLoaded = useCallback(() => {
    setSpinner(false);
  }, []);

  const settings = useMemo(() => {
    return {
      responsive: [
        {
          breakpoint: 977,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
    };
  }, []);

  useEffect(() => {
    if (listingData.length === 0) {
      setSpinner(false);
    }

    setSpinner(true);
  }, [listingData.length]);

  if (props.sliderImagesData.length === 0) {
    return <div className={styles.emptyDiv}> </div>;
  }

  return (
    <>
      {spinner && (
        <>
          <Backdrop />
          <LoadingSpinner />
        </>
      )}
      <div className={styles.sliderMain}>
        <div
          onClick={sliderRef?.slickPrev}
          className={`${styles.sliderBtns} ${styles.left}`}
        >
          {" "}
          <span>
            <svg
              className={styles.fill}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 8"
            >
              <path d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z" />
            </svg>
          </span>
        </div>
        <div
          onClick={sliderRef?.slickNext}
          className={`${styles.sliderBtns} ${styles.right}`}
        >
          <span>
            <svg
              className={styles.fill}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 8"
            >
              <path d="M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z" />
            </svg>
          </span>
        </div>
        <Slider ref={setSliderRef} {...settings}>
          {listingData.map((image, i) => {
            return image !== null ? (
              <div
                onMouseDown={(e) => {
                  setImgDragState({
                    clientXonMouseDown: e.clientX,
                    clientYonMouseDown: e.clientY,
                  });
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    imgDragState.clientXonMouseDown !== e.clientX ||
                    imgDragState.clientYonMouseDown !== e.clientY
                  ) {
                    // prevent link click if the element was dragged
                    e.preventDefault();
                  } else {
                    props.sliderImgClick(image);
                    props.openSingleListing();
                  }
                }}
                key={i}
                className={styles.imgMainDiv}
              >
                <div className={styles.locationAndPriceDiv}>
                  <div className={styles.locationDiv}>
                    <div className={styles.locationMain}>
                      {image.addressOne}
                    </div>
                    <div className={styles.locationSub}>{image.addressTwo}</div>
                  </div>

                  <div className={`${styles.priceDiv} footerAndSearch`}>
                    <div className={styles.priceText}>from</div>
                    <div className={styles.priceNumber}>$ {image.price}</div>
                  </div>
                </div>

                <img
                  onLoad={imgLoaded}
                  alt=""
                  draggable={false}
                  className={styles.imgSlider}
                  src={image.imageUrl}
                />
                <div className={styles["featured-banner"]} />
              </div>
            ) : (
              ""
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default React.memo(ImageSlider);
