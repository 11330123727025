import styles from "./Testimonial.module.css";
import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { TESTIMONIAL_DELAY } from "../../../helpers/constants";

const Testimonial = (props) => {
  const [delay, setDelay] = useState(100);
  const refFeedbackParentDiv = useRef();
  const refButtonsParentDiv = useRef();
  const [sectionThreeData, setSectionThreeData] = useState();

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    if (props.testimonial === undefined) return;

    if (delay !== TESTIMONIAL_DELAY) {
      setDelay(TESTIMONIAL_DELAY);
    }

    const data = props.testimonial;

    setSectionThreeData(data);

    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [props.testimonial, index, delay]);

  const dotsHelper = (idx) => {
    if (refButtonsParentDiv === undefined) return;
    if (index === idx) {
      const arr = [...refButtonsParentDiv.current.children];

      arr.forEach((el) => {
        el.classList.remove(styles["myDot--active"]);
      });

      if (arr[index]) {
        arr[index].classList.add(styles["myDot--active"]);
      }

      const arr2 = [...refFeedbackParentDiv.current.children];

      arr2.forEach((el, i) => {
        if (document.querySelector(`.feedbackText--${i}`))
          document
            .querySelector(`.feedbackText--${i}`)
            .classList.add(styles["not-visible"]);
      });

      arr2[index + 1].classList.remove(styles["not-visible"]);
    }
  };

  return (
    <div className={`${styles["section-three-main-div"]} testimonial`}>
      <div
        ref={refFeedbackParentDiv}
        className={styles["section-three-sub-div-one"]}
      >
        <div
          className={`${styles["quotes-img"]} ${styles["quotes-img-right"]}`}
        />

        {sectionThreeData
          ? sectionThreeData.map((el, i) => {
              return (
                <div
                  key={i}
                  className={`feedbackText--${i} ${styles["main-quotes-div"]} ${styles["not-visible"]}`}
                >
                  <div className={styles.para}>{parse(el)}</div>
                </div>
              );
            })
          : ""}

        <div
          className={`${styles["quotes-img"]} ${styles["quotes-img-left"]}`}
        />
      </div>

      <div ref={refButtonsParentDiv}>
        {sectionThreeData
          ? sectionThreeData.map((_, i, arr) => {
              return (
                <div
                  name="change testimonial"
                  key={i}
                  className={`buttonDot${i} ${styles.myDot} ${
                    index === i ? dotsHelper(i) : ""
                  }`}
                  onClick={() => {
                    setIndex(i);
                    arr.forEach((_, i) => {
                      document
                        .querySelector(`.feedbackText--${i}`)
                        .classList.add(styles["not-visible"]);
                      document
                        .querySelector(`.buttonDot${i}`)
                        .classList.remove(styles["myDot--active"]);
                    });
                    document
                      .querySelector(`.feedbackText--${i}`)
                      .classList.remove(styles["not-visible"]);
                    document
                      .querySelector(`.buttonDot${i}`)
                      .classList.add(styles["myDot--active"]);
                  }}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default React.memo(Testimonial);
