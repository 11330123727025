import React, { useCallback, useEffect, useState } from "react";
import styles from "./Header.module.css";
import LogoAndName from "./LogoAndName/LogoAndName";
import ContactLinks from "./ContactLinks/ContactLinks";
import IFrame from "../Popups/IFrame/IFrame";
import { TENANTCUBE_LIVE, TENANTCUBE_STAGING } from "../../helpers/constants";

const Header = (props) => {
  const [host, setHost] = useState(TENANTCUBE_LIVE);
  const [showMainReqIframe, setShowMainReqIframe] = useState(false);

  const openMaintenanceRequestIframe = useCallback(() => {
    setShowMainReqIframe(true);
  }, []);

  const closeMaintenanceRequestIframe = useCallback(() => {
    setShowMainReqIframe(false);
  }, []);

  useEffect(() => {
    const fullDomain = window.location.hostname;
    const domainParts = fullDomain.split(".");
    const subDomain = domainParts[1];
    if (subDomain === "dev-preview") setHost(TENANTCUBE_STAGING);
  }, []);

  return (
    <header className={`${styles.header} footerAndSearch`}>
      {/* mobile view */}
      <div className={styles.mobViewOtherLinks}>
        {" "}
        <div className={`${styles.mobAboveNavEls} footerAndSearch`}>
          <div className={styles.firstElDiv}>
            <button
              onClick={openMaintenanceRequestIframe}
              className={`${styles.applyNowBtnMob} button`}
            >
              {" "}
              Maintenance Request
            </button>
          </div>

          <a href={`mailto:${props.email}`} className={styles.mobViewAbLinks}>
            <svg className={styles.headerSvgMobile} viewBox="0 0 32 32">
              <path d="M16,14.81,28.78,6.6A3,3,0,0,0,27,6H5a3,3,0,0,0-1.78.6Z" />
              <path d="M16.54,16.84h0l-.17.08-.08,0A1,1,0,0,1,16,17h0a1,1,0,0,1-.25,0l-.08,0-.17-.08h0L2.1,8.26A3,3,0,0,0,2,9V23a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V9a3,3,0,0,0-.1-.74Z" />
            </svg>
          </a>

          <a href={`tel:${props.phone}`} className={styles.mobViewAbLinks}>
            <svg className={styles.headerSvgMobile} viewBox="0 0 32 32">
              <path d="M13.216 8.064c-.382-4.394-4.966-6.55-5.16-6.638a.974.974 0 0 0-.582-.078c-5.292.878-6.088 3.958-6.12 4.086a.99.99 0 0 0 .02.54c6.312 19.584 19.43 23.214 23.742 24.408.332.092.606.166.814.234a.99.99 0 0 0 .722-.042c.132-.06 3.248-1.528 4.01-6.316a.997.997 0 0 0-.096-.612c-.068-.132-1.698-3.234-6.218-4.33a.977.977 0 0 0-.884.21c-1.426 1.218-3.396 2.516-4.246 2.65-5.698-2.786-8.88-8.132-9-9.146-.07-.57 1.236-2.572 2.738-4.2a.998.998 0 0 0 .26-.766z" />
            </svg>
          </a>
        </div>
      </div>
      {/* desktop view */}
      <div className={styles.headerDiv}>
        <LogoAndName logo={props.logo} companyName={props.companyName} />

        <div className={styles.headerDivSecond}>
          <div className={styles.aboveLinksUl}>
            <ContactLinks
              email={props.email}
              phone={props.phone}
              orgUrlName={props.orgUrlName}
            />
          </div>
          <div className={styles.mobNavDiv}>
            <div className={styles.mobNavBtnDiv}></div>
          </div>

          <div className={styles.hideOnMobView}></div>
        </div>
      </div>{" "}
      {/* mobile view iframe */}
      {showMainReqIframe && (
        <IFrame
          title={"Maintenance Request"}
          className={"Maintenance Request"}
          // src={host + "/portal/workorder/freshrental-properties"}
          src={`${host}/portal/workorder/${props.orgUrlName}`}
          onClose={closeMaintenanceRequestIframe}
        />
      )}
    </header>
  );
};

export default React.memo(Header);
