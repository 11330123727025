import PlanRoom from "../OptionalSections/PlanRoom/PlanRoom";
import React, { useMemo } from "react";
import Testimonial from "../OptionalSections/Testimonial/Testimonial";
import About from "../OptionalSections/About/About";
import ImageSlider from "../ImageSlider/ImageSlider";
import TestimonialSingle from "../OptionalSections/Testimonial/TestimonialSingle";

const HomePage = (props) => {
  const testimonials = useMemo(() => {
    if (props.portal.testimonial) return JSON.parse(props.portal.testimonial);
    else return [];
  }, [props.portal.testimonial]);

  return (
    <>
      <ImageSlider
        sliderImgClick={props.sliderImgClick}
        openSingleListing={props.openSingleListing}
        sliderImagesData={props.sliderImagesData}
      />

      {props.portal.show_about && <About about={props.portal.about} />}

      {props.portal.show_testimonial &&
        (testimonials.length === 1 ? (
          <TestimonialSingle testimonial={testimonials[0]} />
        ) : (
          <Testimonial testimonial={testimonials} />
        ))}

      {props.portal.show_plan_room && (
        <PlanRoom
          backgroundImg={props.portal.background_img}
          planRoom={props.portal.plan_room}
        />
      )}
    </>
  );
};

export default React.memo(HomePage);
