import styles from "./About.module.css";
import parse from "html-react-parser";
import { useEffect, useState } from "react";

const About = (props) => {
  const [heading, setHeading] = useState("");
  // const [subHeading, setSubHeading] = useState();

  useEffect(() => {
    if (props.about) {
      try {
        const data = JSON.parse(props.about);
        setHeading(() => parse(data.aboutHtml ? data.aboutHtml : ""));
      } catch (error) {
        console.error(error.message);
        setHeading("");
      }

      // setSubHeading(() => data.sectionOneDes);
    }
  }, [props.about]);

  return (
    <div className={styles.sectionOneDiv}>
      <div className={styles.flex_body}>
        {/* <h1 className={`${styles.heading} textColor`}>{heading}</h1> */}
        <div className={styles.subHeading}>{heading}</div>
      </div>
    </div>
  );
};

export default About;
