import React, { useCallback, useEffect, useMemo, useState } from "react";
import Slider from "react-slick/lib/slider";
import IFrame from "../Popups/IFrame/IFrame";
import Backdrop from "../UI/Backdrop/Backdrop";
import styles from "./ListingDetailsPage.module.css";

const ListingDetailsPage = (props) => {
  const [applyNowIframe, setApplyNowIframe] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [sliderRef, setSliderRef] = useState(null);
  const [sliderRefOne, setSliderRefOne] = useState(null);

  const settings = useMemo(() => {
    return {
      responsive: [
        {
          breakpoint: 977,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      arrows: false,
      draggable: false,
    };
  }, []);

  const {
    utilities,
    phone,
    addressOne,
    shareUrl,
    imageUrl,
    photos,
    price,
    name,
    beds,
    bath,
    size,
    parking,
    description,
    amenities,
    features,
    rentersInsure,
    laundry,
    petFriendly,
    number,
    street,
    city,
    province,
    zipcode,
    countryCode,
    showUtility,
    orgName,
    orgEmail,
    orgPhone,
  } = useMemo(() => props.singleListingData, [props.singleListingData]);

  useEffect(() => {
    if (photos.includes(imageUrl)) return;
    if (imageUrl) photos.push(imageUrl);
  }, [photos, imageUrl]);

  useEffect(() => {
    let iframeSrc = document.getElementById("iframeId");
    const latlonUrl = `https://maps.google.com/?q=${
      number ? number + "," : ""
    } ${street}, ${city}, ${province}, ${zipcode}, ${countryCode}&output=embed`;
    iframeSrc.src = latlonUrl;
  });

  const onClose = useCallback(() => setShowSlider(false), []);
  const onCloseIframe = useCallback(() => setApplyNowIframe(false), []);
  const onShowSlider = useCallback(() => {
    setShowSlider(true);
  }, []);
  const onApplyNowIframe = useCallback(() => setApplyNowIframe(true), []);

  return (
    <div className={styles.bgGrey}>
      <div className={styles.container}>
        <div>
          <button
            className={styles.backButton}
            onClick={props.closeSingleListing}
          >
            <svg className={styles.backArrow} viewBox="0 0 512 512">
              <path d="M427 234.625H167.296l119.702-119.702L256 85 85 256l171 171 29.922-29.924-118.626-119.701H427v-42.75z" />
            </svg>
          </button>
        </div>
        <div className={styles.partOne}>
          <div className={styles.sliderDiv}>
            <div>
              {" "}
              <span
                className={`${styles.sliderBtnFixed} ${styles.prevOne}`}
                onClick={sliderRefOne?.slickPrev}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="rgba(255, 255, 255, 0.544)"
                  viewBox="0 0 8 8"
                >
                  <path d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z" />
                </svg>
              </span>
              <span
                className={`${styles.sliderBtnFixed} ${styles.nextOne} `}
                onClick={sliderRefOne?.slickNext}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="rgba(255, 255, 255, 0.544)"
                  viewBox="0 0 8 8"
                >
                  <path d="M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z" />
                </svg>
              </span>
            </div>
            <Slider ref={setSliderRefOne} {...settings} dots={false}>
              {photos.map((element, i) => {
                return (
                  <div key={i}>
                    <img
                      className={styles.sliderImg}
                      onClick={onShowSlider}
                      alt=""
                      src={element}
                      draggable={false}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className={styles.detailsDiv}>
            <h1 className={styles.buildingName}>{street} </h1>
            <div className={styles.propertyAddress}>
              {street} - {city}, {countryCode}{" "}
            </div>
            <div className={styles.priceAndUtility}>
              {showUtility && (
                <div className={styles.utilitiesDiv}>
                  <h2 className={styles.utilitesHeading}>Utilities Included</h2>
                  <ul className={styles.utilitiesList}>
                    {utilities.map((el, i) => {
                      if (el.paidBy === 1) return "";

                      if (el.name === "electricity")
                        return (
                          <li key={i} className={styles.utility}>
                            <div
                              className={` ${styles.icon} ${styles.electricity}`}
                            ></div>
                          </li>
                        );
                      if (el.name === "heat")
                        return (
                          <li key={i} className={styles.utility}>
                            <div
                              className={` ${styles.icon} ${styles.heat}`}
                            ></div>
                          </li>
                        );
                      if (el.name === "water")
                        return (
                          <li key={i} className={styles.utility}>
                            <div
                              className={` ${styles.icon} ${styles.water}`}
                            ></div>
                          </li>
                        );

                      return "";
                    })}
                  </ul>
                </div>
              )}
              <div>
                <div className={styles.monthlyRent}>Monthly Rent</div>
                <div className={styles.rentPrice}>{"$ " + price}</div>
              </div>
            </div>
            <div className={styles.bedBaths}>
              {beds && <div>{`${+beds} bed(s)`}</div>}
              {bath && <div>{`${+bath} bath(s)`}</div>}
              {size && <div>{`${+size} size (sqft.)`}</div>}
              {parking && <div>{`${+parking} parking(s)`}</div>}
            </div>
            <p className={`${styles.rentQuestion} textColorTwo`}>
              Interested in renting here?
            </p>
            <div className={styles.availability}>
              <p
                onClick={onApplyNowIframe}
                className={`${styles.applyNowBtn} searchBtnAndSlider`}
              >
                <span className="text">Apply Now</span>
              </p>
            </div>

            <div className={styles.phoneContainer}>
              {phone ? (
                <div className={styles.phoneDiv}>
                  <div className={styles.circle}></div>
                  <h2 className={styles.phoneSubHeader}>Local Phone</h2>
                  <a href={`tel:${props.phone}`} className={styles.phone}>
                    {phone}
                  </a>
                </div>
              ) : orgPhone ? (
                <div className={styles.phoneDiv}>
                  <div className={styles.circle}></div>
                  <h2 className={styles.phoneSubHeader}>Local Phone</h2>
                  <a href={`tel:${orgPhone}`} className={styles.phone}>
                    {orgPhone}
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {description !== null ? (
          <div className={styles.description}>
            <label className={styles.amenitiesFeaturesTitle}>Description</label>
            <div className={styles.descriptionContent}>{description}</div>
          </div>
        ) : (
          ""
        )}
        {features.length !== 0 ? (
          <div>
            <div className={styles.amenitiesFeatures}>
              <label className={styles.amenitiesFeaturesTitle}>Features</label>
              <div className={styles.amenitiesFeaturesList}>
                <ul>
                  {features.map((data) => (
                    <li>{data}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {amenities.length !== 0 ? (
          <div>
            <div className={styles.amenitiesFeatures}>
              <label className={styles.amenitiesFeaturesTitle}>Amenities</label>
              <div className={styles.amenitiesFeaturesList}>
                <ul>
                  {amenities.map((data) => (
                    <li>{data}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className={styles.laundryPey}>
          <div className={styles.otherInfo}>
            <label className={styles.laundryPetFont}>Renter Insurance</label>{" "}
            <p>{rentersInsure ? "Required" : "Not Required"}</p>
          </div>
          <div>
            {laundry === 1 ? (
              <div>
                <label className={styles.laundryPetFont}>Laundry</label>{" "}
                <p>In-suite Laundry</p>
              </div>
            ) : laundry === 2 ? (
              <div>
                <label className={styles.laundryPetFont}>Laundry</label>{" "}
                <p>Shared Laundry</p>
              </div>
            ) : (
              <div>
                <label className={styles.laundryPetFont}>Laundry</label>{" "}
                <p>None</p>
              </div>
            )}
          </div>
          <div className={styles.petFriendly}>
            {petFriendly === true ? (
              <div>
                <label className={styles.laundryPetFont}>Pet Friendly</label>{" "}
                <p>Yes</p>
              </div>
            ) : (
              <div>
                <label className={styles.laundryPetFont}>Pet Friendly</label>{" "}
                <p>No</p>
              </div>
            )}
          </div>
        </div>
        <div>
          <iframe
            title="map"
            id="iframeId"
            width="100%"
            height="500px"
            className={styles.mapIframe}
          ></iframe>
        </div>

        {/* end */}
      </div>
      {applyNowIframe && (
        <IFrame onClose={onCloseIframe} src={shareUrl + "/apply"} />
      )}
      {showSlider && (
        <>
          <div>
            <span
              className={`${styles.sliderBtn} ${styles.prev}`}
              onClick={sliderRef?.slickPrev}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="rgba(255, 255, 255, 0.544)"
                viewBox="0 0 8 8"
              >
                <path d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z" />
              </svg>
            </span>
            <span
              className={`${styles.sliderBtn} ${styles.next}`}
              onClick={sliderRef?.slickNext}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="rgba(255, 255, 255, 0.544)"
                viewBox="0 0 8 8"
              >
                <path d="M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z" />
              </svg>
            </span>
            <Slider className={styles.slider} ref={setSliderRef} {...settings}>
              {photos.map((element, i) => {
                return (
                  <div key={i} className={styles.imgMainDiv}>
                    <img
                      alt=""
                      src={element}
                      draggable={false}
                      className={styles.imgSlider}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>

          <Backdrop onClose={onClose} />
        </>
      )}
    </div>
  );
};

export default React.memo(ListingDetailsPage);
